import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import ReactRotatingText from 'react-rotating-text';
import PortfolioContext from '../../context/context';

// import heroGif from '../../images/webdev-gif.gif';
import snowVideo from '../../videos/snow.mp4';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, subtitle, cta } = hero;
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <div className="hero-video-container">
        <video className="hero-video" playsInline src={snowVideo} autoPlay loop muted />
      </div>
      <Container className="hero-container">
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          {/* <img
            className="heroGif img-fluid"
            src={heroGif}
            alt="animation"
            style={{
              marginBottom: '1 rem',
              textAlign: 'center',
            }}
          /> */}
          <h1 className="hero-title">
            {title || 'Hi! My name is'} {isMobile && <br />}
            <span className="text-color-main">
              <ReactRotatingText items={['Phil Browne']} />
            </span>
            <br />
            {subtitle || "I'm a Software Engineer based in the Washington, DC area."}
          </h1>
          <p className="hero-cta">
            <Link to="about" smooth duration={1000}>
              <span className="cta-btn cta-btn--hero">{cta || 'Know more'}</span>
            </Link>
          </p>
        </Fade>
        {/* <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px"> */}
        {/* <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p> */}
        {/* </Fade> */}
      </Container>
    </section>
  );
};

export default Header;
