import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import ReactRotatingText from 'react-rotating-text';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import beachVideo from '../../videos/beach.mp4';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn } = contact;
  const [isLargeDisplay, setIsLargeDisplay] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 1200) {
      setIsLargeDisplay(true);
    } else {
      setIsLargeDisplay(false);
    }
  }, []);

  return (
    <section id="contact">
      <div className="contact-video-container">
        <video className="contact-video" playsInline src={beachVideo} autoPlay loop muted />
      </div>
      <Container className="contact-container" style={{ marginTop: '2rem' }}>
        <Title title="Contact" />
        <Fade bottom duration={700} delay={500} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {isLargeDisplay ? (
                <ReactRotatingText
                  items={[cta || 'Please feel free to reach out, I would love to hear from you!']}
                />
              ) : (
                cta || 'Please feel free to reach out, I would love to hear from you!'
              )}
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="mailto:pbrowne@gmail.com"
            >
              {btn || 'Email Me'}
            </a>
            {/* <div
              className="inbound-form-wrapper contact-form"
              id="form_5871"
              data-path="https://app.99inbound.com/i/7a0f6b6e-a175-4edb-a7e1-64b1c286d1f8"
              data-token="9mSQsaAiQazQkBQWqQjMwQtt"
            /> */}
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
