import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description, image } = headData;

  return (
    <>
      {/* <Meta property="image" content={image} />
      <Meta property="og:image" content={image} />
      <Meta name="title" content={title} />
      <Meta property="og:title" content="Phil Browne | Software Engineer" />
      <Meta property="og:url" content="https://www.philbrowne.com" />
      <Meta
        name="description"
        content={description || 'Phil Browne - Software Engineer based in Washington, DC area'}
      />
      <Title> {title || 'Phil Browne | Software Engineer'}</Title> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Phil Browne | Software Engineer'}</title>
        <html lang={lang || 'en'} />
        <meta property="og:image" content={image} />
        <meta property="image" content={image} />
        <meta name="image" property="og:image" content={image} />
        <meta property="og:type" content="website" />
        <meta name="title" content={title} />
        <meta property="og:title" content="Phil Browne | Software Engineer" />
        <meta property="og:url" content="https://www.philbrowne.com" />
        <meta
          name="description"
          content={description || 'Phil Browne - Software Engineer based in Washington, DC area'}
        />
        <meta name="description" property="og:description" content={description} />
        <meta name="author" content="Phil Browne" />
        <meta
          name="og:description"
          content={description || 'Phil Browne - Software Engineer based in Washington, DC area'}
        />
        <meta name="title" content="Phil Browne | Software Engineer" />
        <meta property="og:type" content="website" />
        {/* <script type="text/javascript" src="https://app.99inbound.com/99inbound.js" /> */}
      </Helmet>
      <App />
    </>
  );
};
