import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavLink, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';
import brandLogo from '../../images/brand-logo.png';
import '../../style/components/TopNav.css';

const TopNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="light" light expand="md" fixed="top">
        <span className="TopNav-link navbar-brand">
          <Link
            to="hero"
            smooth
            duration={1000}
            onClick={() => {
              if (isOpen) toggle();
            }}
          >
            <img src={brandLogo} width="150" alt="brandLogo" />
          </Link>
        </span>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <span className="TopNav-link nav-link">
                <Link
                  to="about"
                  smooth
                  duration={1000}
                  onClick={() => {
                    if (isOpen) toggle();
                  }}
                >
                  <b>About</b>
                </Link>
              </span>
            </NavItem>
            <NavItem>
              <span className="TopNav-link nav-link">
                <Link
                  to="projects"
                  smooth
                  duration={1000}
                  onClick={() => {
                    if (isOpen) toggle();
                  }}
                >
                  <b>Projects</b>
                </Link>
              </span>
            </NavItem>
            <NavItem>
              <span className="TopNav-link nav-link">
                <Link
                  to="contact"
                  smooth
                  duration={1000}
                  onClick={() => {
                    if (isOpen) toggle();
                  }}
                >
                  <b>Contact</b>
                </Link>
              </span>
            </NavItem>
          </Nav>
          <Nav navbar className="mt-3 mt-lg-0">
            <div>
              <NavLink
                className="TopNav-link d-inline mx-3 Nav-Icon"
                href="https://www.linkedin.com/in/philbrownetech"
                target="_blank"
                onClick={() => {
                  if (isOpen) toggle();
                }}
              >
                <i className="fa fa-linkedin" />
              </NavLink>
              <NavLink
                className="TopNav-link d-inline mx-3 Nav-Icon"
                href="https://github.com/philipbrowne"
                target="_blank"
                onClick={() => {
                  if (isOpen) toggle();
                }}
              >
                <i className="fa fa-github" />
              </NavLink>
              <NavLink
                className="TopNav-link d-inline mx-3 Nav-Icon"
                href="https://twitter.com/PhilBuildsStuff"
                target="_blank"
                onClick={() => {
                  if (isOpen) toggle();
                }}
              >
                <i className="fa fa-twitter" />
              </NavLink>
              <NavLink
                className="TopNav-link d-inline mx-3 Nav-Icon"
                href="mailto:pbrowne@gmail.com"
                target="_blank"
                onClick={() => {
                  if (isOpen) toggle();
                }}
              >
                <i className="fa fa-envelope" />
              </NavLink>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default TopNav;
