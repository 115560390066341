import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import ReactRotatingText from 'react-rotating-text';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import forestVideo from '../../videos/forest.mp4';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallDisplay, setIsSmallDisplay] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
      setIsSmallDisplay(false);
    } else if (window.innerWidth > 400) {
      setIsMobile(true);
      setIsSmallDisplay(false);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsSmallDisplay(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <div className="about-video-container">
        <video className="about-video" playsInline src={forestVideo} autoPlay loop muted />
      </div>
      <Container className="about-container" style={{ marginTop: '2rem' }}>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            {isSmallDisplay && (
              <h4 className="section-sub-title">
                <ReactRotatingText items={['Welcome!']} />
              </h4>
            )}
            <Fade
              left={isDesktop}
              bottom={isMobile}
              duration={isDesktop ? 1000 : 400}
              delay={isDesktop ? 1000 : 400}
              distance="30px"
            >
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    'Hello and thank you so much for visiting my page! I am a software engineer with experience developing applications with JavaScript, React.js, Node.js, Express.js, Python, and Flask. I am passionate about helping people through technology, making the world more accessible and inclusive for people of all backgrounds, and finding ways to efficiently solve problems to make a positive difference.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    'I have a background of more than a decade as a professional musician as well as several years of experience in communications and administration. I would love to explore ways that I can help your organization succeed.'}
                </p>
                <p className="about-wrapper__info-text">
                  <b>Technical Skills</b>
                  <br />
                  <b>Languages:</b> HTML5, CSS, JavaScript, Python, Go/Golang
                  <br />
                  <b>Libraries and Frameworks:</b> Bootstrap, jQuery, React.js, Node.js, Express.js,
                  Flask, REST, GraphQL, gRPC, Selenium
                  <br />
                  <b>Databases:</b> SQL (PostgreSQL, SQLite, MySQL), MongoDB, ElasticSearch, Neo4j,
                  <br />
                  <b>Testing:</b> Jasmine, Jest, React Test Library, PyTest
                  <br />
                  <b>CI/CD & Deployment:</b> Docker, Kubernetes, AWS, Jenkins, GitHub, GitLab
                </p>
                {/* {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )} */}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
